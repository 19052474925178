@font-face {
  font-family: aReg;
  src: url("fonts/afd20/aRegular.woff");
}

@font-face {
  font-family: aM;
  src: url("fonts/afd20/aM.woff");
}

@font-face {
  font-family: aBold;
  src: url("fonts/afd20/aBold.woff");
}

@font-face {
  font-family: bReg;
  src: url("fonts/afd20/bRegular.woff");
}
@font-face {
  font-family: hpReg;
  src: url("fonts/afd20/hpReg.woff");
}
@font-face {
  font-family: hn65;
  src: url("fonts/afd20/hn65.woff");
}

@keyframes wiper {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

::-webkit-scrollbar {
  display: none;
}

/* ================================= 
  Element Styles
==================================== */

* {
  box-sizing: border-box;
}

*:focus,
button:focus {
  outline: 3px solid initial;
}

header h3 {
  margin: initial;
  display: block;
  height: auto;
}

body {
  margin: initial;
  height: auto;
  min-height: 200vh;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  color: #4c4c4c;
  background: #f6f7f7;
  /*   padding-top: 145px; */
  /*   padding-bottom: 3em; */
}

body.loaded,
body[data-active="LOT"],
body[data-active="lot"],
body[data-active="FP"],
body[data-active="SPECIES"],
body[data-active="ORIGIN"],
body[data-active="CLEANLINESS"],
body[data-active="CONTENT"],
body[data-active="TREATMENT"],
body[data-active="PROCESSING"],
body[data-active="CERTIFICATIONS"],
body[data-active="CM"],
body[data-active="SUSTAINABILITY"],
body[data-active="CARE"] {
  min-height: initial;
}

a {
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single-post-interior > ul li:first-child {
  margin-bottom: 2em;
}

label {
  /* padding:0.5em; */
}

input {
  color: #f8f8f9;
  /*   width:100%; */
  width: auto;
  font-size: 1.12em;
  line-height: 0.8em;
  height: auto;
  padding-bottom: 1em;
  padding-left: 0;
  background: none;
  border: none;
  outline: 0;
  border-bottom: 2px solid #e4e4e4;
  transition: border-color 0.4s;
}

button {
  width: 40px;
  height: 35px;
  border: 0;
  background: none;
  cursor: pointer;
  outline: 0;
  position: relative;
  z-index: 2000;
}

button.search-button {
  height: auto;
  transform: translateX(-50%);
}

/* ================================= 
  Page Components
==================================== */

.main-title {
  font-size: 1.4em;
  line-height: 80px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

.search-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  margin: auto;
  /*
	top:30px;
	left:30px;
*/
  /* 	top:160px; */
  /*   bottom:200px; */
  /*   left:30px; */
}
.search-form input:only-of-type {
  width: 100%;
  margin-right: -40px;
  padding-right: 35px;
}

.search-button {
  float: right;
}

.icn-search {
  font-weight: 700;
  color: #f8f8f9;
}

#help {
  position: absolute;
  top: 0.5em;
  right: -1.8em;
  right: 0;
  padding: initial;
  height: 1.12em;
  width: 1.12em;
  color: #f8f8f9;
  cursor: pointer;
}

.icon-gif {
  font-size: 6em;
  margin-bottom: 0;
  color: #7bcbc4;
  display: block;
}

.no-gifs {
  color: #7bcbc4;
  height: 140px;
  padding-top: 62px;
  text-align: center;
  font-size: 1.25em;
}

.gif-list a {
  display: block;
  margin: auto;
}

.gif-wrap {
  background-color: #fff;
  margin-bottom: 0.8em;
  position: relative;
}

.gif-wrap img {
  min-width: 100%;
}

/*tmd*/
.mainWrap {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}
.dataEntry {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5%;
}

.newEntry-btn,
.dataEntry input[type="submit"] {
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 1.25em;
  height: initial;
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  background: #ccc;
  border: none;
  outline: 0;
  border-bottom: none;
  align-self: flex-end;
  order: 100;
}
.newEntry-btn {
  width: 25%;
  margin: auto;
}
.dataEntry-values {
  display: flex;
}
.tmdspec {
  text-align: left;
}
.tmdspec .title {
  font-weight: bold;
  font-size: 0.8em;
}

label[for="down_cluster"] {
  order: 1;
}
label[for="down_fiber"] {
  order: 2;
}
label[for="feather_fiber"] {
  order: 3;
}
label[for="waterfowl_feather"] {
  order: 4;
}
label[for="broken_feather"] {
  order: 5;
}
label[for="quill"] {
  order: 6;
}
label[for="land_fowl"] {
  order: 7;
}
label[for="residue"] {
  order: 8;
}
label[for="oxygen"] {
  order: 9;
}
label[for="turbidity"] {
  order: 10;
}

label[for="prefix"] {
  order: -12;
}
label[for="display_lot"] {
  order: -11;
}
label[for="production_lot"] {
  order: -10;
}
label[for="facility"] {
  order: -9;
}
label[for="origin"] {
  order: -8;
}
label[for="treatment"] {
  order: -7;
}
label[for="rds"] {
  order: -6;
}
label[for="recycled"] {
  order: -5;
}
label[for="down_type"] {
  order: -4;
}
label[for="stated_fp"] {
  order: -3;
}
label[for="verified_fp"] {
  order: -2;
}
label[for="rptLink"] {
  order: -1;
}

.tmdspec,
label[for="prefix"],
label[for="display_lot"] label[for="production_lot"],
label[for="down_type"],
label[for="stated_fp"],
label[for="verified_fp"],
label[for="treatment"],
label[for="rds"],
label[for="recycled"] {
  width: 33.333%;
}

label[for="facility"],
label[for="origin"] {
  width: 50%;
}

label[for="rptLink"] {
  width: 100%;
}
label[for="rptLink"] {
  margin-bottom: 4em;
}

label[for="origin_percent"] {
  display: none;
  visibility: hidden;
}

label[for="rds"] input,
label[for="recycled"] input {
  display: inline-block;
  width: 2em;
  margin: 0 0 0 0;
}

label[for="treatment"] select {
  display: inline-block;
  margin: 0 0 0 7px;
}

/* ================================= 
  Layout Styles
==================================== */

.inner,
.main-content {
  width: 90%;
  max-width: 1025px;
  margin: auto;
}

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  min-height: 80px;
  z-index: 1000;
  padding-bottom: 1em;
}

.main-content::after {
  content: " ";
  display: table;
  clear: both;
}

.is-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* ================================= 
  Media Queries
==================================== */

@media (min-width: 0) and (max-width: 549px) {
  .gif-list li {
    text-align: center;
    margin: 20px 0 0;
    font-size: 1em;
    line-height: 1.2em;
  }
}

@media (min-width: 550px) {
  .gif-list li {
    float: left;
    width: 50%;
    margin: 0;
  }
  .specs li {
    float: initial;
  }
  .gif-wrap img {
    height: 235px;
  }
  .gif-list .no-gifs {
    float: none;
    width: 100%;
  }
  .gif-wrap {
    border: 1px solid white;
    padding: 0;
    overflow: hidden;
  }
}

@media (min-width: 769px) {
  body {
    /*     padding-top: 115px; */
  }
  .main-title {
    float: left;
  }
  .search-form {
    width: 422px;
    /*     margin-top: 34px; */
  }
  .gif-list li {
    width: 33.333333333%;
  }
  .specs li {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .gif-list li:nth-of-type(3n + 1) {
    clear: left;
  }
}

/* ================================= 
  af+d WIP
==================================== */

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.4em;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  background-color: #000;
  color: #fff;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

body div.il-add,
body video.il-add {
  /*   opacity:0; */
  transform: scale(1.01);
  /*   transition:transform 750ms ease 250ms, opacity 750ms ease 500ms; */
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;

  opacity: 0.01;
  transition: opacity 250ms ease;
}

body div.il-add {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  transform: scale(1.025);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

body video.il-add,
body .vidWrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  transform: scale(1.025);
  object-fit: cover;
  /*debug*/
  /*   opacity: 1; */
  /*
  will-change:opacity;
  transition: opacity 750ms ease 750ms;
*/
}

body.add video.il-add,
body.add .vidWrap {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

body.imgUp div.il-add,
body.imgUp video.il-add,
body.imgUp vidWrap {
  opacity: 1;
  transition: opacity 250ms ease;
}

body[data-active="SPECIES"] .upper div.il-add {
  background-position: center 60%;
}

body[data-active="SPECIES"] .lower-right div.il-add {
  background-position: center 90%;
}

body[data-active="CM"] .lower-left div.il-add {
  background-position: center top;
}
body[data-active="CM"] .upper div.il-add {
  background-position: center 10%;
}

body[data-active="ORIGIN"] .lower-right div.il-add {
  background-position: bottom;
}

body[data-active="CARE"] .upper div.il-add {
  background-position: center;
}

body[data-active="CERTIFICATIONS"] .main-right[data-treatment="false"] .active-desc:before {
  opacity: 1;
  background-color: transparent;
  background-image: url("img/certs/cert-two.svg");
}

body[data-active="CERTIFICATIONS"] .active-desc:before {
  opacity: 1;
  background-color: transparent;
  background-image: url("img/certs/cert-one.svg");
}

body[data-active="FP"] video.il-add {
  /*   opacity: 1; */
  /*   transition: opacity 750ms ease-in-out 250ms; */
  /*   transition: opacity 750ms ease-in-out 750ms; */
}

body.add[data-active="FP"].isActive video.il-add {
  /*
  opacity: 1;  
  transition:none;
*/
}

body.add.fp .lower-left {
  /*   flex:0; */
}

body.add.pr div.il-add,
body.add.cr div.il-add,
body.add.cm div.il-add {
  /*   transition:none */
}

body.add.cr .lower-right div.il-add {
  background-size: 75% 75%;
  background-repeat: no-repeat;
}

body .il {
  opacity: 0;
  transform: scale(0.98);
  transition: all 750ms ease;
  transition-delay: 500ms;
}

body.loaded .il {
  opacity: 1;
  transform: scale(1);
}

body.loaded .il.upper-bg {
  /* 	transition-duration: 500ms; */
  /* 	transition-delay:initial; */
}

body.loaded[data-active="FP"] .il.upper-bg,
body.loaded[data-active="SPECIES"] .il.upper-bg,
body.loaded[data-active="ORIGIN"] .il.upper-bg,
body.loaded[data-active="CLEANLINESS"] .il.upper-bg,
body.loaded[data-active="CONTENT"] .il.upper-bg,
body.loaded[data-active="TREATMENT"] .il.upper-bg,
body.loaded[data-active="PROCESSING"] .il.upper-bg,
body.loaded[data-active="CERTIFICATIONS"] .il.upper-bg,
body.loaded[data-active="CM"] .il.upper-bg,
body.loaded[data-active="CARE"] .il.upper-bg {
  opacity: 0;
  transition: all 500ms ease;
  /* 	transition-duration: 250ms; */
  /* 	transition-delay:750ms; */
}

body .content-title .il {
  transform: scale(0.98);
}
body.loaded .content-title .il {
  transition-delay: 0ms;
}

main,
#root {
  min-height: 100vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

h1,
body.loaded header a {
  margin: 0;
  width: 120px;
  width: 90px;
  font-weight: normal;
  font-size: 1.9em;
  line-height: 1em;
  position: absolute;
  top: 45px;
  left: 45px;
  color: transparent;
  background-image: url("img/logos/tmd-f.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

body.loaded header a {
  position: relative;
  display: block;
  top: 15px;
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  body.loaded header a {
    position: absolute;
    top: 45px;
    width: auto;
  }
}

@media only screen and (min-width: 1210px) {
  body.loaded header a,
  h1 {
    width: auto;
  }
}
body.loaded header {
  height: 50px;
}

body.loaded header a {
  left: 30px;
  height: 30px;
}

section,
aside {
  height: 100%;
}

section {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

aside {
  width: 100%;
}

.contentWrap {
  box-sizing: border-box;
  padding-bottom: 45px;
  height: auto;
  width: 100%;
  margin: auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1210px) and (min-height: 690px) {
  .contentWrap {
    margin-top: 90px;
  }
}

.content-hat {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* 	height:45%; */
  width: 100%;
  /* 	background-color:red; */
}

.content-title,
.content-summary {
  box-sizing: border-box;
}

.content-title {
  text-align: center;
  text-align: left;
  width: 100%;
  margin-bottom: 45px;
  padding-left: 10px;
}
.content-title p,
.content-title a {
  font-family: aM, serif;
  line-height: 1em;
  margin: initial;
  padding-top: 3px;
  color: inherit;
}
.content-title img {
  width: 100%;
  max-width: 150px;
  max-height: 55px;
  padding-top: 3px;
}

@media (min-width: 769px) {
.content-title,
.content-summary {
  padding-left: 30px;
}
}


.content-summary {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.content-summary .cert-grid {
  position: absolute;
  bottom: 0;
  left: 30px;
  /* left: 60px; */
  width: 35%;
  width: 50%;
  opacity: 1;
  /* 	background-color:green; */
  padding-top: 30px;
  transform: translateY(100%);
  display: flex;
  flex-direction: row;
  /* 	justify-content: space-between; */
  align-items: center;
  cursor: pointer;
}

.content-summary .cert-grid.rds,
.content-summary .cert-grid.grs {
  width: 250px;
}

.cert-grid.rds .bluesign {
  margin-left: 15px;
}

.content-summary div[isgrs="true"] .rds{
  display:none;
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  .content-summary .cert-grid {
    left: 50px;
  }
  .content-summary .cert-grid.rds,
  .content-summary .cert-grid.grs {
    width: 230px;
    left: 12px;
    left: 40px;
  }
}

@media only screen and (min-width: 1210px) {
  .content-summary .cert-grid {
    /*   left: 20px; */
  }
  .content-summary .cert-grid.rds,
  .content-summary .cert-grid.grs {
    width: 60%;
  }
}

@media screen and (min-width: 1366px) and (min-height: 690px) {
  .content-summary .cert-grid {
    left: 20px;
  }
}

body.add .content-summary .cert-grid {
  /*
	transform: translateY(-260%);
	padding-top:initial;
*/
}

.content-summary label {
  display: block;
  width: 75%;
  width: 95%;
  margin: auto;
  margin-left: 30px;
  margin-left: 10px;
  list-style-type: none;
  font-family: aM, serif;
  font-size: 9px;
  letter-spacing: 0.1em;
  line-height: 1.6em;
  padding: 0;
  transition: transform 500ms ease;
  transform: translateX(0);
}

.content-summary label[for="species"] {
  text-transform: uppercase;
}

.content-summary label:hover {
  transform: translateX(-1%);
}

.content-summary li {
  position: relative;
}
.content-summary li:hover {
  cursor: default;
}

.content-summary input[type="radio"] {
  position: fixed;
  opacity: 0;
  margin: initial;
  /* 	pointer-events: none; */
}

.content-summary li:before,
.content-summary label:before {
  opacity: 0;
  content: "\25FD";
  margin-right: 1.1em;
  font-size: 9px;
  line-height: 1.6em;
}

/* .content-summary input[type=radio]:focus + label:before, */
.content-summary input[type="radio"]:hover + label:before,
.content-summary input[type="radio"]:checked + label:before {
  opacity: 1;
}

.content-hat,
.content-face {
  padding-top: 45px;
}

.content-face {
  width: 100%;
  /* 	background-color:#85144b; */
  /* 	overflow-y: scroll; */
  overflow-y: hidden;
  padding-top: 135px;
  padding-bottom: 45px;

  /*debug*/
  /* 	height:0; */
  /* 	opacity: 0; */
  /* 	display:none; */
  /*     visibility: hidden; */

  /* 	transition: opacity .5s, visibility 0s .5s, height 0s .5s; */
  transition: all 500ms ease;
}

body.add .content-face {
  display: block;
  height: auto;
}

.content-face.active {
  opacity: 1;
  visibility: visible;
  /* 	height:auto; */
  /* 	transition: opacity .5s, visibility 0s .5s, height 0s .5s; */
  transition: all 500ms ease;
}

.ac-input {
  margin-left: 10px;
  display: none;
}

.ac-input ~ .ac-text {
  opacity: 0;
  height: 0;
  display: none;
  visibility: none;
}

.ac-text a {
  color: inherit;
  text-decoration: none;
  border-bottom: solid 1px #fff;
}

.ac-input:checked ~ .ac-text {
  opacity: 1;
  height: auto;
  display: block;
  visibility: visible;
}

.ac-input:checked,
.ac-input:checked ~ .ac-label {
  position: absolute;
  top: 10px;
}

.ac-input:checked ~ .ac-label {
  width: 91%;
}

.ac-input:checked {
  top: 20px;
  left: 0;
}

.ac-input:checked ~ .ac-label {
  left: 60px;
}
/* .content-ac.tr .ac-input:checked ~ .ac-label */
.content-ac.cr .ac-input:checked ~ .ac-label,
.content-ac.cm .ac-input:checked ~ .ac-label,
.content-ac.pr .ac-input:checked ~ .ac-label,
.content-ac.su .ac-input:checked ~ .ac-label {
  left: 60px;
  left: 25px;
}

.ac-input ~ .ac-label:before {
  position: relative;
  top: 0;
  left: -10px;
  content: "+";
  color: #fff;
}
.ac-input ~ .ac-label:after {
  position: relative;
  top: 0;
  right: -10px;
  content: "";
  color: #fff;
}

.ac-input:checked ~ .ac-label:before {
  content: "–";
}
.ac-input:checked ~ .ac-label:after {
  content: "";
}

.content-ac {
  box-sizing: border-box;
  position: relative;
  margin: auto 45px;
  margin: auto 35px;
  margin: auto 2.5%;
  border-bottom: solid 1px #fff;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content-ac,
.content-ac > table,
.content-ac p.data,
.content-ac a {
  font-family: aM, serif;
  font-size: 10px;
}
.content-ac > table {
  line-height: 1em;
}
.content-ac > table td {
  text-align: justify;
  height: 1em;
}
.content-ac > table td:after {
  content: "";
  font-size: 0;
  line-height: 0;
  font-family: serif;
  display: inline-block;
  width: 100%;
}

.content-ac > table td:last-child {
  padding-left: 30px;
}

.content-ac p {
  font-family: aReg, serif;
  font-size: 12px;
  line-height: 1.8em;
}

.content-ac.active {
  height: auto;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  display: block;
  border-top: solid 1px #fff;
}

@media only-screen and (min-width: 1210px) {
  .content-ac.active {
    max-height: 38vh;
    padding-bottom: 20px;
    padding-bottom: 40px;
  }
}

.content-ac.hide {
  display: none;
  visibility: hidden;
}

.content-ac.tr.active,
.content-ac.ca.active,
.content-ac.cr.active,
.content-ac.cm.active,
.content-ac.pr.active {
  display: block;
  border-top: solid 1px #fff;
}

.content-ac.ca,
.content-ac.cr,
.content-ac.cm,
.content-ac.pr {
  display: none;
}

.content-ac.tr .ac-text table {
  cellspacing: 0;
  border-spacing: 0;
  margin: auto;
  width: 85%;
}

.content-ac.tr .ac-text tr {
  border-bottom: solid 1px #fff;
}
.content-ac.tr .ac-text th {
  padding: 1.2em 0;
}
.content-ac.tr .ac-text tr:nth-of-type(2) td {
  border-top: solid 1px #fff;
}
.content-ac.tr .ac-text td {
  padding: 0;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
}
.content-ac.tr .ac-text td:first-child {
  text-align: right;
  padding-right: 1em;
  border-left: none;
}

.content-ac.tr .ac-text td:last-child {
  line-height: 1.4em;
  padding: 1em 0 1em 2em;
  border-right: none;
}

.ac-logo,
.ac-title {
  /*   position:absolute; */
  position: relative;
  top: 0;
  display: inline-block;
}

.content-ac.active .ac-logo,
.content-ac.active .ac-title {
  position: absolute;
}

.ac-logo {
  left: 10px;
  height: 1em;
  width: 1em;
}

.ac-title {
  top: 0;
  right: 0;
}
.ac-title,
.ac-label {
  width: calc(100% - 60px);
  letter-spacing: 0.075em;
  text-align: left;
  transform: translateX(0.5%);
  transition: transform 500ms ease;
}

.content-ac:hover .ac-input ~ .ac-label {
  transform: translateX(-1%);
}
.content-ac:hover .ac-input:checked ~ .ac-label {
  transform: translateX(-1.5%);
}

.content-ac:before {
  content: "";
  height: 20px;
  width: 20px;
  margin-left: 10px;
  position: relative;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(1.75);
  mix-blend-mode: lighten;
}

.content-ac.sp:before {
  background-image: url("img/logos/icon-species.png");
}

.content-ac.or:before {
  background-image: url("img/logos/icon-origin.png");
}

.content-ac.fp:before {
  background-image: url("img/logos/icon-fp.png");
}

.content-ac.cl:before {
  background-image: url("img/logos/icon-cleanliness.png");
}

.content-ac.tr:before {
  width: 20px;
  background-size: 45%;
  background-position: 55% 50%;
  opacity: 0.55;
}

.content-ac.tr[data-feature="HyperDry"]:before {
  background-image: url("img/logos/hyperDRY-icon-solo.svg");
}

.content-ac.tr[data-feature="ExpeDRY"]:before {
  opacity: 1;
  background-size: 90% 90%;
  background-image: url("img/logos/expeDRY-icon-solo.svg");
}

.content-ac.tr[data-feature="UltraWht"]:before {
  opacity: 1;
  background-size: 90% 90%;
  background-image: url("img/logos/ultraWHT-icon-solo.svg");
}

.content-ac.tr[data-feature="ThermaDown O2+"]:before {
  background-size: 90% 90%;
  background-image: url("img/logos/thermaDownO2-plus-icon-solo.svg");
}

.content-ac.co:before {
  background-image: url("img/logos/icon-contents.png");
}

.content-ac.ca:before {
  background-image: url("img/logos/icon-care.png");
}

.ac-info {
  font-size: 10px;
  line-height: 1.8em;
  display: none;
  visibility: hidden;
}

.content-ac.active .ac-info {
  display: block;
  visibility: visible;
}

.content-ac.active .ac-info,
.single-post-interior {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1112px;
}

.single-post-interior table {
  width: auto;
  max-width: 325px;
}

@media screen and (min-width: 620px) {
  .single-post-interior table {
    width: auto;
    max-width: unset;
  }
  body[data-active="FP"] .single-post-interior table {
    width: 200px;
  }
}

body[data-active="CLEANLINESS"] .single-post-interior table {
  width: 175px;
}

body[data-active="CONTENT"] .single-post-interior table td {
  text-align: justify;
  line-height: 0.8em;
  line-height: 1.6em;
}

body[data-active="CONTENT"] .single-post-interior table td:last-child {
  padding-left: 3em;
}

body[data-active="CONTENT"] .single-post-interior table td span {
  text-align: left;
}

body[data-active="CONTENT"] .single-post-interior table td:after {
  /*   content: ""; */
  font-size: 1px;
  line-height: 1em;
  display: inline-block;
  width: 100%;
}

.main-right {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-left: solid 1px #000;
}

#root[data-brand="1524"] .main-left{
  background-color:#3A4A58;
}

#root[data-brand="1524"] .main-right {
  border-left: solid 1px #3A4A58;
}


.main-right:before {
  display: none;
}

.upper:before,
.lower > div:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  left: 100%;
  background-color: #000;
  opacity: 0.01;
  transition: opacity 0ms ease-in-out;
}

body.wipeOne .upper:before,
body.wipeOne .lower > div:before {
  opacity: 1;
  left: 0;
}

/* body.wipeLeft .main-right:before */
body.wipeLeft .upper:before,
body.wipeLeft .lower > div:before {
  animation-name: wiper;
  animation-duration: 1200ms;
  animation-iteration-count: 1;
}

body.wipeOne.wipeLeft .upper:before,
body.wipeOne.wipeLeft .lower > div:before {
  left: 100%;
}

/* .upper, .lower{flex:1;} */
.upper,
.upper-bg,
.lower-left,
.lower-right {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.lower-left .active-lot {
  display: inline-block;
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 60%;
  transform: translateY(-100%);
  /*		
	left:0;
	right:0;	
	margin:0;
*/
}
.lower-left .active-lot h3,
.lower-right .spec-value {
  margin: initial;
  font-family: hn65, serif;
  font-weight: normal;
  color: #ebebeb;
  font-size: 22px;
  /* 	padding-top:15px; */
  /* 	transform:translateX(10.72%); */
}

.lower-left .active-lot h3 {
  margin-left: 25px;
}

body.loaded.ca .lower-left .active-lot,
body.loaded[data-active="CARE"] .lower-left .active-lot {
  visibility: hidden;
}

.lower-left .active-lot:before {
  content: "";
  position: absolute;
  top: -5px;
  /* 	left:50px; */
  left: 25px;
  z-index: 10;
  height: 22px;
  width: 60px;
  background-repeat: no-repeat;
  background-image: url("img/lot-no.min.svg");
  background-size: contain;
  transform: translateY(-120%);
}

@media screen and (min-width: 620px) {
  .lower-left .active-lot {
    /*   transform: translateY(-50%);	 */
  }
  .lower-left .active-lot:before {
    top: 0;
    width: 100px;
    left: 25px;
    right: 0;
    transform: translateY(-210%);
  }
  .lower-left .active-lot h3 {
    text-align: left;
    font-size: 40px;
    transform: initial;
  }
}

/*
@media screen and (max-height: 600px) {
.lower-left .active-lot:before{
	top:10px;
	width:60px;
	left:25px;	
	right:0;
	transform:translateY(-175%);
}
.lower-left .active-lot h3{
  text-align:left;
  font-size:20px
}
}*/

.lower-left:after {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  left: 0;
  right: 0;
  height: 55px;
  width: 55px;
  margin: auto;
  background-repeat: no-repeat;
  background-image: url("img/logos/rds.min.svg");
  background-size: contain;
}

.upper {
  position: relative;
  height: 75%;
  height: 50vh;
  min-height: 320px;
  order: 2;
  overflow: hidden;
}

.upper-credit,
.lower-credit {
  font-family: aReg, serif;
  position: absolute;
  z-index: 10;
}

.lower-credit,
body.sp .lower-right:after {
  font-family: aBold, serif;
}

.lower-credit table {
  width: 100%;
}
.lower-credit span,
.lower-right .spec-title {
  font-family: aM, serif;
}

body[data-active="ORIGIN"] .lower-right .active-desc .spec-title,
body[data-active="CM"] .lower-right .active-desc .spec-title,
body[data-active="CM"] .lower-right .active-desc .spec-value,
body[data-active="SUSTAINABILITY"] .lower-left .active-lot {
  opacity: 0.01;
}

.main-right[data-ddfp="true"] .sticky-desc .spec-title,
.main-right[data-ddfp="true"] .sticky-desc .spec-value,
.main-right[data-ddfp="true"] .active-desc .spec-title,
.main-right[data-ddfp="true"] .active-desc .spec-value {
  visibility: hidden;
}

.upper-credit {
  font-size: 9px;
  padding: 40px 0 0 20px;
  position: initial;
}

@media screen and (min-width: 960px) {
  .upper-credit {
    position: absolute;
    top: 30px;
    right: 20px;
    right: 13px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: #fff;
    /*   mix-blend-mode:multiply; */
  }
}

@media only screen and (min-width: 1400px) {
  .upper-credit {
    padding: 20px 20px 0 20px;
  }
}

.lower-credit {
  width: 95%;
  opacity: 0;
  top: 20px;
  left: 20px;
  color: #fff;
  mix-blend-mode: screen;
  transition: opacity 1000ms ease;
  transition-delay: 1000ms;
}

body.loaded .lower-credit {
  opacity: 1;
}

body.fp .lower-credit,
body.cl .lower-left .lower-credit,
body.co .lower-bg .lower-credit {
  font-size: 2.5vw;
  line-height: 1em;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 85%;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

body.co .lower-bg .lower-credit {
  font-size: 18px;
  lext-align: left;
}

body.cl .lower-left .lower-credit,
body.co .lower .lower-credit tr:first-child {
  text-align: justify;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 1em;
}
body.co .lower .lower-credit tr:first-child:after {
  content: "";
  font-size: 0;
  line-height: 0;
  font-family: serif;
  display: inline-block;
  width: 100%;
}

body.co .lower .lower-credit td:last-child {
  text-align: right;
  padding: 2.5% 0;
}
body.co .lower .lower-credit tr:last-child {
  font-size: 1.8em;
  margin-top: 1.2em;
}

body.fp .lower-credit,
body.cl .lower-left .lower-credit {
  max-width: 11em;
  font-size: 1.8em;
}

body.cl .lower-left .lower-credit td:last-child {
  text-align: right;
}

body.cl .lower .lower-left .lower-credit {
  opacity: 0;
  transition: opacity 500ms ease-in-out 1500ms;
}
body.cl.add .lower .lower-left .lower-credit {
  opacity: 1;
}

body.cl .lower .lower-right,
body.co .lower .lower-right > .lower-credit {
  /*   opacity:0; */
}

body.tr .lower-credit,
body.sp .lower-credit,
body.pr .lower-credit,
body.cr .lower-credit,
body.cm .lower-credit {
  opacity: 0;
  transition-delay: 750ms;
}

body.sp .lower-right:after {
  font-family: aM, serif;
  opacity: 1;
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  content: "ANSER ANSER F. DOMESTICA \A WHITE GOOSE";
  white-space: pre-line;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 1.8em;
  line-height: 1em;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 11em;
  margin: auto;
}

.upper-bg,
.lower-bg {
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  transform: scale3d(1, 1, 1);
  transform-origin: center;
  transition: all 1000ms ease;
}

body .il.upper-bg {
  position: relative;
  background-image: url("img/aside-one.jpg");

  width: 100%;
  /*height is funky, explicit works but percent does not ugh*/
}

body .main-right[data-grs="true"] .il.upper-bg {
  background-image: url("img/aside-one-grs.jpg");
}

body.loaded.fp .il.upper-bg:before,
body.loaded.pr .il.upper-bg:before {
  left: 0;
}

/* 
  .upper-bg:before, 
  .lower .lower-bg:before
*/

body .upper:before,
.lower > div:before {
  /*
	content:'';
	position:absolute;
	left:0;
	opacity:1;
	z-index:99;
	height:100%;
	width:0.01px;
	background-color:#000;
*/
  /* 	transition:opacity 750ms ease, width 750ms ease, left 750ms ease-in-out 500ms; */
}

.lower > div:before {
  /*debug*/
  /* 	opacity:0.01; */
}

body[data-active="CARE"] .lower > div:before {
  display: none;
}

body.fp .lower-left {
  transition: none;
}

body.ca .upper-bg:before,
body.or .upper-bg:before
/* body.fp .upper-bg:before */ {
  opacity: 1;
  /* 	width:100%; */
  left: 100%;
}

/* body.loaded .upper-bg:before, */
body.wiper .upper:before,
body.wiper .lower > div:before {
  /* 	background-color:rgba(0,0,0,1); */
  /* 	opacity:0.01; */
  left: 0;
  width: 0;
  /* 	transition:opacity 750ms ease-in-out 750ms, left 0ms ease, width 750ms ease-in-out; */
  animation-name: wiper;
  animation-duration: 1600ms;
  animation-iteration-count: 1;
  /*   left:100%; */
}

body.loaded.add .upper-bg:before {
  /*   transition:opacity 250ms ease-in-out; */
  /*   width:25%; */
}

body.loaded.fp .upper-bg:before,
body.loaded.sp .upper-bg:before,
body.loaded.or .upper-bg:before,
body.loaded.cl .upper-bg:before,
body.loaded.co .upper-bg:before,
body.loaded.tr .upper-bg:before,
body.loaded.pr .upper-bg:before,
body.loaded.cr .upper-bg:before,
body.loaded.cm .upper-bg:before,
body.loaded.ca .upper-bg:before,
body[data-active="FP"] .upper:before,
body[data-active="SPECIES"] .upper:before,
body[data-active="ORIGIN"] .upper:before,
body[data-active="CLEANLINESS"] .upper:before,
body[data-active="CONTENT"] .upper:before,
body[data-active="TREATMENT"] .upper:before,
body[data-active="PROCESSING"] .upper:before,
body[data-active="CERTIFICATIONS"] .upper:before,
body[data-active="CM"] .upper:before,
body[data-active="CARE"] .upper:before {
  /* 	background-color:#000; */
  /* 	width:100%; */
  /* 	left:100%; */
  /* 	opacity:1; */
}

body.loaded.fp .upper-bg:before,
body.loaded.sp .upper-bg:before,

body[data-active="FP"] .upper:before,
/*
body[data-active="SPECIES"] .upper:before,
body[data-active="ORIGIN"] .upper:before,
body[data-active="CLEANLINESS"] .upper:before,
body[data-active="CONTENT"] .upper:before,
body[data-active="TREATMENT"] .upper:before,
body[data-active="PROCESSING"] .upper:before,
body[data-active="CERTIFICATIONS"] .upper:before,
body[data-active="CM"] .upper:before,
body[data-active="CARE"] .upper:before
*/ {
  transition: width 750ms ease-in-out, opacity 750ms ease-in-out,
    left 750ms ease-in-out 750ms;
}

body[data-active="FP"] .upper:before,
body[data-active="SPECIES"] .upper:before,
body[data-active="ORIGIN"] .upper:before,
body[data-active="CLEANLINESS"] .upper:before,
body[data-active="CONTENT"] .upper:before,
body[data-active="TREATMENT"] .upper:before,
body[data-active="CERTIFICATIONS"] .upper:before,
body[data-active="PROCESSING"] .upper:before,
body[data-active="CM"] .upper:before,
body[data-active="CARE"] .upper:before {
  /* 	transition:none; */
  /* 	animation-name: wiper; */
  /*     animation-duration: 1600ms; */
  /*     animation-iteration-count:1; */
}

body[data-active="FP"] .lower > div:before,
body[data-active="SPECIES"] .lower > div:before,
body[data-active="ORIGIN"] .lower > div:before,
body[data-active="CLEANLINESS"] .lower > div:before,
body[data-active="CONTENT"] .lower > div:before,
body[data-active="TREATMENT"] .lower > div:before,
body[data-active="CERTIFICATIONS"] .lower > div:before,
body[data-active="PROCESSING"] .lower > div:before,
body[data-active="CM"] .lower > div:before,
body[data-active="CARE"] .lower > div:before {
  /* 	animation-name: wiper; */
  /*     animation-duration: 1600ms; */
  /*     animation-iteration-count:1; */
}

body.loaded.sp .active-desc,
body.loaded.or .active-desc,
body.loaded.co .active-desc,
body.loaded.tr .active-desc,
body.loaded.pr .active-desc,
body.loaded.ca .active-desc {
  opacity: 1;
  transition: opacity 750ms ease-in-out 750ms;
}

body.loaded.cl .lower .lower-bg:before {
  transition: height 750ms ease-in-out, opacity 750ms ease-in-out 750ms,
    top 750ms ease-in-out 750ms;
}

body.loaded.cl .active-desc {
  opacity: 1;
  transition: opacity 750ms ease-in-out 750ms;
}

body.loaded.co .lower .lower-bg:before {
  /* 	transition:opacity 750ms ease-in-out 750ms; */
}

body.loaded.fp .upper-bg:before,
body.loaded.sp .upper-bg:before,
body.loaded.or .upper-bg:before,
body.loaded.cl .upper-bg:before,
body.loaded.co .upper-bg:before,
body.loaded.tr .upper-bg:before,
body.loaded.pr .upper-bg:before,
body.loaded.cr .upper-bg:before,
body.loaded.cm .upper-bg:before,
body.loaded.ca .upper-bg:before {
  transition: height 750ms ease-in-out, width 750ms ease-in-out,
    opacity 750ms ease-in-out, left 750ms ease-in-out 750ms;
}

body.loaded.cl .upper-bg:before,
body.loaded.tr .upper-bg:before {
  /*
	width:100%;
	opacity:1;
	background-color:#000;
	transition:width:750ms ease, opacity 750ms ease-in-out 1000ms;
*/
}

body.loaded.cl .upper-bg:before {
  /* opacity:0;transition:opacity 750ms ease-in-out 1500ms; */
}
body.loaded.tr .upper-bg:before {
  /* opacity:0; */
}

body.loaded.co .lower .lower-left .lower-bg:before,
body.loaded.co .lower .lower-right .lower-bg:before {
  /*
	width:100%;
	height:100%;
	opacity:0;
	background-color:#000;
*/
}

body .lower-bg div.il-add:before {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
body.cl.add .lower-bg div.il-add:before {
  content: "";
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
}

body.or .upper,
body.ca .upper {
  /* 	height:100%; */
}

body.or .lower,
body.ca .lower {
  /* 	min-height:0; */
  /* 	height:0; */
}

body.loaded .lower .lower-bg:before {
  left: 0;
  background-color: #000;
  opacity: 1;
  width: 100%;
  height: 0;
}

body.loaded.cl .lower .lower-bg:before {
  background-color: #000;
  height: 100%;
  width: 100%;
  opacity: 0;
}

body.loaded .sticky-desc,
body.loaded .active-desc {
  /*   transition:opacity 750ms ease-in-out 500ms;	 */
  /*   opacity:0.01; */
}
body.loaded .active-desc,
body.loaded.sp .sticky-desc,
body.loaded.cl .sticky-desc,
body.loaded.or .sticky-desc,
body.loaded.co .sticky-desc,
body.loaded.tr .sticky-desc,
body.loaded.pr .sticky-desc,
body.loaded.ca .sticky-desc,
body.loaded.ca .active-lot {
  /*
	opacity:0;
	transition-duration: 250ms;
*/
}

body.loaded.fp .lower .lower-bg:before,
body.loaded.ca .lower .lower-bg:before {
  opacity: 1;
  left: 100%;
  /*   transition:opacity 750ms ease-in-out 750ms; */
}

body.pr .lower .lower-bg:before {
  height: 100%;
  opacity: 1;
  transition: opacity 750ms ease 750ms;
}

body.pr.add .lower .lower-bg:before {
  opacity: 0;
}

.lower-bg {
  background-size: cover;
}

.upper {
  position: relative;
}

.upper .certs {
  position: absolute;
  z-index: 10;
  width: 80px;
  height: 200px;
  bottom: 0;
  left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.certs > div,
.cert-grid > div {
  background-repeat: no-repeat;
  opacity: 1;
  /* 	flex:1; */
  z-index: 10;
  transition: all 1000ms ease 750ms;
  background-position: center center;
}

body.loaded .certs > div,
body.loaded .cert-grid > div {
  /* 	opacity:0.5; */
  /* 	margin:auto; */
}

.certs .bluesign,
.cert-grid .bluesign {
  height: 30px;
  width: 60px;
  transform: translateX(-1.5px);
  background-image: url("img/logos/blueSign.min.svg");
}

.certs .rds,
.cert-grid .rds {
  height: 60px;
  width: 60px;
  /* 	width:100%; */
  background-image: url("img/logos/rds.min.svg");
}

.cert-grid .grs {
  height: 60px;
  width: 60px;
  margin-left: 15px;
  /* 	width:100%; */
  background-image: url("img/logos/GRS.min.svg");
}

body[data-active="SPECIES"]
  .main-right[data-grs="true"][data-fp="750"]
  .active-desc:before {
  background-image: url("img/logos/renu-750.min.svg");
}
body[data-active="SPECIES"]
  .main-right[data-grs="true"][data-fp="700"]
  .active-desc:before {
  background-image: url("img/logos/renu-700.min.svg");
}
body[data-active="SPECIES"]
  .main-right[data-grs="true"][data-fp="600"]
  .active-desc:before {
  background-image: url("img/logos/renu-600.min.svg");
}

.certs .oeko,
.cert-grid .oeko {
  height: 25px;
  height: 43px;
  width: 70px;
  width: 81px;
}
.cert-grid .oeko {
  margin-left: 15px;
  background-image: url("img/logos/OkeoTex100-f.min.svg");
}

.cert-grid .aafa {
  /*   height:45px; */
  height: 30px;
  width: 80px;
  background-size: contain;
  background-image: url("img/logos/aafa.png");
}

.upper .frameWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: blue;
  overflow: hidden;
}

.upper iframe.vimeo {
  width: 140%;
  height: 140%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.upper-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.upper-bg:hover,
.upper-bg img {
  transform: scale3d(1.1, 1.1, 1);
}

.lower {
  order: 1;
  height: 25%;
  min-height: 150px;
  display: flex;
  flex-direction: row;
  transition: height 1000ms ease-in-out;
}

body.fp .lower {
  /*   min-height:50%;	 */
  /*   height:30%; */
}

body.cl .lower {
  /*   min-height:50%;		 */
  /*   flex-direction: column; */
}

body.cl .lower .lower-right {
  /*   flex:0; */
  /*   min-height:0; */
  /*   height:0;	 */
}

.lower-left,
.lower-right {
  position: relative;
}

.lower-left .lower-bg {
  background-image: url("img/aside-two.jpg");
}
.main-right[data-grs="true"] .lower-left .lower-bg {
  background-image: url("img/aside-two-grs.jpg");
}

.main-right[data-active="TREATMENT"][data-treatment="UltraWht"] .lower-left .lower-bg,
.main-right[data-active="TREATMENT"][data-treatment="UltraWht"] .lower-right .lower-bg {
  background-image:none;
}

.main-right[data-active="TREATMENT"][data-treatment="UltraWht"] .lower-left .il-add,
.main-right[data-active="TREATMENT"][data-treatment="UltraWht"] .lower-right .il-add {
  opacity:0.45;
}

body.loaded .il.lower-bg {
  opacity: 0.75;
}

body.or .upper-bg {
  background-image: url("img/aside-one-origin.jpg");
}
body.tr .upper-bg {
  background-image: url("img/aside-one-tr.jpg");
}

body.tr .lower-left .lower-bg {
  background-image: url("img/aside-two-tr.jpg");
}

.lower-right .lower-bg {
  background-image: url("img/aside-three.jpg");
}
.main-right[data-grs="true"] .lower-right .lower-bg {
  background-image: url("img/aside-three-grs.jpg");
}

.lower-right .active-desc,
.lower-right .sticky-desc {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  /*
	transition:opacity 750ms ease 1500ms;
	opacity:0;
*/
}

body[data-active="CERTIFICATIONS"] .lower-right .active-desc > * {
  opacity: 0;
}

.lower-right .sticky-desc {
  opacity: 1;
  transition-delay: 0ms;
}
.lower-right .sticky-desc .spec-value,
.lower-right .sticky-desc .spec-title,
.lower-right .active-desc .spec-value,
.lower-right .active-desc .spec-title {
  /*
	position:absolute;
	height:100%;
	width:100%;
	top:50%;
	left:0;
	right:0;
	margin:auto;	
	transform-origin: center;
*/
  /* 	transform:translate3d(-50%,-50%, 0); */
}

.lower-right .sticky-desc .spec-value,
.lower-right .active-desc .spec-value {
  display: inline-block;
  opacity: 0.4;
  font-size: 100px;
  font-size: 65px;
  letter-spacing: 0;
  padding-top: 4px;
  line-height: 0.8em;
  transform: translate3D(-2.5%, 10%, 0);
}

body[data-active="SUSTAINABILITY"] .lower-right .active-desc .spec-value {
  font-size: 80px;
  transform: translate3D(0, 10%, 0);
}

.lower-right .active-desc .spec-value {
  text-transform: uppercase;
}

.lower-right .sticky-desc .spec-title,
.lower-right .active-desc .spec-title {
  margin: initial;
  position: absolute;
  font-size: 12px;
  text-transform: uppercase;
  color: #ebebeb;
  z-index: 20;
  /* 	transform: translate3D(110%, 0, 0); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

body.loaded .lower-right .sticky-desc .spec-title,
body.loaded .lower-right .active-desc .spec-title,
body.loaded.fp .lower-right .sticky-desc .spec-title,
body.loaded.fp .lower-right .active-desc .spec-title,
body.loaded.cr .lower-right .sticky-desc .spec-title,
body.loaded.cr .lower-right .active-desc .spec-title,
body.loaded.cm .lower-right .sticky-desc .spec-title,
body.loaded.cm .lower-right .active-desc .spec-title,
body.loaded.ca .lower-right .sticky-desc .spec-title {
  transform: translate3D(110%, -11%, 0);
}

.lower-right .sticky-desc .spec-title,
body.loaded[data-active="FP"] .lower-right .active-desc .spec-title,
body.loaded[data-active="CM"] .lower-right .active-desc .spec-title {
  transform: translate3D(110%, -11%, 0);
}

.main-right[data-loc="fr"] .lower-right .sticky-desc .spec-title {
  transform: translate3D(50%, -11%, 0);
}

body.loaded.sp .lower-right .active-desc .spec-title,
body.loaded.or .lower-right .active-desc .spec-title,
body.loaded.cl .lower-right .active-desc .spec-title,
body.loaded.tr .lower-right .active-desc .spec-title,
body.loaded.pr .lower-right .active-desc .spec-title,
body.loaded.ca .lower-right .active-desc .spec-title,
body.loaded[data-active="CONTENT"] .lower-right .active-desc .spec-title,
body.loaded[data-active="SPECIES"] .lower-right .active-desc .spec-title,
body.loaded[data-active="ORIGIN"] .lower-right .active-desc .spec-title,
body.loaded[data-active="CLEANLINESS"] .lower-right .active-desc .spec-title,
body.loaded[data-active="TREATMENT"] .lower-right .active-desc .spec-title,
body.loaded[data-active="PROCESSING"] .lower-right .active-desc .spec-title,
body.loaded[data-active="SUSTAINABILITY"] .lower-right .active-desc .spec-title,
body.loaded[data-active="CARE"] .lower-right .active-desc .spec-title {
  transform: translate3D(0%, 0%, 0);
}

body[data-active="SPECIES"] .lower-right .lower-bg {
  background-position: center 90%;
}

body[data-active="CM"] .lower-right .il-add {
  background-position: center 25%;
}

body.fp .lower-right .lower-bg {
  opacity: 0.5;
  transition-delay: 0ms;
}

body.sp .lower-right .lower-bg,
body.cl .lower-left .lower-bg,
body.cl .lower-right .lower-bg,
body.pr .lower-left .lower-bg,
body.or .lower-right .lower-bg,
body.tr .lower-left .lower-bg,
body.tr .lower-right .lower-bg,
body.cr .lower-left .lower-bg,
body.cr .lower-right .lower-bg,
body.cm .lower-left .lower-bg,
body.cm .lower-right .lower-bg,
body.ca .lower-left .lower-bg,
body.ca .lower-right .lower-bg,
body[data-active="CLEANLINESS"] .lower-left .lower-bg,
body[data-active="CLEANLINESS"] .lower-right .lower-bg,
body[data-active="CERTIFICATIONS"] .lower-right .lower-bg,
body[data-active="CARE"] .lower-left .lower-bg,
body[data-active="CARE"] .lower-right .lower-bg {
  background-image: none;
}

body.co .lower-left .lower-bg,
body.co .lower-right .lower-bg {
  background-image: none;
}

body.loaded.cr .lower-right .sticky-desc .spec-title,
body.loaded.cr .lower-right .sticky-desc .spec-value {
  opacity: 0;
}

body.fp .lower-right .lower-bg .il-add,
body.sp .lower-right .lower-bg .il-add,
body.or .lower-right .lower-bg .il-add,
.il-add.grey {
  /* 	transition-delay:750ms; */
  /*   opacity:0.8; */
  filter: grayscale(0.9);
}

body.tr .upper-bg .il-add {
  position: relative;
}

body .il.upper-bg:before,
body.tr .upper-bg .il-add:before,
body.cr .upper-bg .il-add:before,
.upper .il-add:before,
.upper .vidWrap:before,
.active-desc:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 10;
  height: 100%;
  width: 100%;
  opacity: 0.45;
  background-repeat: no-repeat;
  background-size: 20% 20%;
  background-position: center;
}

body .il.upper-bg:before,
.upper .vidWrap:before {
  opacity: 1;
}

.active-desc:before {
  background-size: 100% 100%;
}

body.tr .upper-bg .il-add:before,
.main-right[data-treatment="HyperDry"] .upper .il-add.tr:before {
  background-image: url("img/logos/hyperDRY-icon-solo.svg");
}

.main-right[data-treatment="ExpeDRY"] .upper .il-add.tr:before {
  background-image: url("img/logos/expeDRY-icon-solo.svg");
}

.main-right[data-treatment="UltraWht"] .upper .il-add.tr:before {
  background-image: url("img/logos/ultraWHT-icon-solo.svg");
  opacity:1;
}

.main-right[data-treatment="ThermaDown O2+"] .upper .il-add.tr:before {
  background-image: url("img/logos/thermaDownO2-plus-icon-solo.svg");
}

body .il.upper-bg:before, 
body.cr .upper-bg .il-add:before,
.upper .il-add.cr:before,
body[data-active="CERTIFICATIONS"]
  .main-right[data-rds="true"]
  .upper
  .il-add:before,
body[data-active="CERTIFICATIONS"] .main-right .upper .il-add:before,
body[data-active="CERTIFICATIONS"] .main-right .upper .vidWrap:before {
  background-image: url("img/logos/rds.min.svg");
}

body .main-right[data-grs="true"] .il.upper-bg:before{
  background-image: url("img/logos/GRS.min.svg");
  background-size:252px 116px;
}

/*
body div[data-brand="0502"] .il.upper-bg:before,
body div[data-brand="0502"].cr .upper-bg .il-add:before,

body[data-active="CERTIFICATIONS"] div[data-brand="0502"] .main-right .upper .il-add:before,
body[data-active="CERTIFICATIONS"] div[data-brand="0502"] .main-right .upper .vidWrap:before,
body[data-active="CERTIFICATIONS"] div[data-brand="0502"] aside[data-loc="fr"] .main-right .upper .il-add:before,
body[data-active="CERTIFICATIONS"] div[data-brand="0502"] aside[data-loc="fr"] .main-right .upper .vidWrap:before
*/
body div[data-rsd="true"] .il.upper-bg:before,
body div[data-rsd="true"].cr .upper-bg .il-add:before,
body div[data-rsd="true"] aside[data-active="CERTIFICATIONS"] .upper .il-add:before,
body div[data-rsd="true"] aside[data-active="CERTIFICATIONS"] .upper .vidWrap:before{
  background-image: url("img/logos/ResponsiblySourcedDown.svg");
}

body div[data-rsd="true"] aside[data-loc="fr"] .il.upper-bg:before,
body div[data-rsd="true"] aside[data-loc="fr"].cr .upper-bg .il-add:before,
body div[data-rsd="true"] aside[data-active="CERTIFICATIONS"][data-loc="fr"] .upper .il-add:before,
body div[data-rsd="true"] aside[data-active="CERTIFICATIONS"][data-loc="fr"] .upper .vidWrap:before{
  background-image: url("img/logos/ResponsiblySourcedDown-fr.svg");
  content:"";
}

body[data-active="SUSTAINABILITY"] .main-right .upper .il-add:before {
  background-image: url("img/logos/pow.min.svg");
}

body[data-active="CERTIFICATIONS"]
  .main-right[data-grs="true"]
  .upper
  .il-add:before,
body[data-active="CERTIFICATIONS"]
  .main-right[data-grs="true"]
  .upper
  .vidWrap:before {
  background-image: url("img/logos/GRS.min.svg");
}

body[data-active="CERTIFICATIONS"]
  .main-right[data-rds="true"]
  .upper
  .il-add:before,
body[data-active="CERTIFICATIONS"]
  .main-right[data-grs="true"]
  .upper
  .il-add:before
body[data-active="CERTIFICATIONS"]
  .main-right[data-rds="true"]
  .upper
  .vidWrap:before,
body[data-active="CERTIFICATIONS"]
  .main-right[data-grs="true"]
  .upper
  .vidWrap:before,
body[data-active="SUSTAINABILITY"] .main-right .upper .il-add:before {
  opacity: 1;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}

body[data-active="CERTIFICATIONS"]
  .main-right[data-rds="true"]
  .upper
  .il-add:before,
body[data-active="CERTIFICATIONS"]
  .main-right[data-rds="true"]
  .upper
  .vidWrap:before,
body[data-active="SUSTAINABILITY"] .main-right .upper .il-add:before {
  background-size: 40% 40%;
}

body div[data-brand="0502"] .il.upper-bg:before,
body div[data-brand="0502"].cr .upper-bg .il-add:before{
 background-size: 50% 50%;
}

@media screen and (min-width: 960px) and (min-height: 768px) {
  body .il.upper-bg:before,
  body[data-active="CERTIFICATIONS"]
    .main-right[data-rds="true"]
    .upper
    .il-add:before,
  body[data-active="CERTIFICATIONS"]
    .main-right[data-rds="true"]
    .upper
    .vidWrap:before,
  body[data-active="SUSTAINABILITY"] .main-right .upper .il-add:before,
  body div[data-brand="0502"] .il.upper-bg:before,
  body div[data-brand="0502"].cr .upper-bg .il-add:before{
    background-size: 30% 30%;
  }
}

body[data-active="CERTIFICATIONS"]
  .main-right[data-grs="true"]
  .upper
  .il-add:before,
body[data-active="CERTIFICATIONS"]
  .main-right[data-grs="true"]
  .upper
  .vidWrap:before,
body[data-active="SUSTAINABILITY"] .main-right .upper .il-add:before {
  background-size: auto 15%;
}

body.tr .lower-right .lower-bg {
  /*   background-image:url(img/aside-three-tr.jpg); */
}

body .lower-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-style: normal;
}

body .lower-right div.species {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  transition: opacity 0.5s, visibility 0s 0.5s;
  font-family: aM, serif;
}

body .lower-right div.species small {
  font-family: aM, serif;
  font-size: x-small;
}
body .lower-right div.species em {
  font-style: normal;
}

body.sp .lower-right div.species {
  display: flex;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

.menu-overlay,
body .lower-right div.species {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 99;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

body.menu .menu-overlay,
body.sp .lower-right div.species {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 620px) {
  .content-hat {
    flex-direction: row;
  }
}

@media screen and (min-width: 960px) and (min-height: 690px) {
  .contentWrap {
    flex-direction: row;
    padding-bottom: 0;
  }
  .contentWrap {
    padding-top: 90px;
    margin-top: 0;
  }

  .content-hat,
  .content-summary,
  .content-face {
    box-sizing: border-box;
    width: 50%;
  }

  .content-face {
    width: 100%;
    height: initial;
  }
  .content-summary {
    width: 100%;
  }

  .ac-input:checked ~ .ac-text {
    /* 	max-height:38vh; */
    /*overflow-y:scroll; */
  }

  .content-ac.active .ac-info,
  .single-post-interior {
    /*     padding-bottom:90px;  */
  }

  main,
  #root {
    height: 100vh;
    flex-direction: row;
  }
  body.intro #root {
    height: auto;
  }
  section {
    height: 100%;
    width: 50%;
  }

  .main-left {
    padding-top: 275px;
    padding-top: 125px;
  }
  .contentWrap {
    /*     height:calc(100% - 90px); */
    flex: 1;
    /*
    align-items: center;
    justify-content: center;
*/
    height: 100%;
    padding-top: 0;
    flex-direction: column;
  }

  .content-hat {
    box-sizing: border-box;
    flex-direction: row;
    flex-direction: column;
    /* 	height:35%; */
    /* 	flex:0.5; */
    width: 100%;
    padding-top: 0;
  }

  .content-face {
    height: 50vh;
    /* 	height:65%; */
    display: none;
    /*     flex:0.5; */
    /* 	padding-left:44%; */
    padding-left: 40px;
    padding-top: 0;
  }

  body.add .content-hat {
    /* 	padding-top:15%; */
    padding-bottom: 15%;
    /*     flex:0.5; */
  }
  body.add .content-face {
    display: block;
    padding-top: 45px;
  }

  .content-title {
    width: 42%;
    /*     margin-bottom:0; */
  }
  .content-title,
  .content-summary {
    box-sizing: border-box;
    height: 100%;
    /* 	padding-top:15%; */
  }
  .content-summary {
    width: 58%;
  }

  .content-summary ul,
  .content-summary label {
    width: 100%;
    margin: 0;
  }

  aside {
    width: 50%;
  }

  .content-ac {
    margin-left: 0;
    margin-right: 0;
    width: 72.5%;
    width: 90%;
  }

  .upper {
    height: 75%;
    order: 1;
    transition: all 1000ms ease-in-out;
  }

  body.fp .upper {
    /* 	height:70%;	   */
  }

  .upper:hover {
    background-size: 110%;
  }
  .lower {
    order: 2;
  }

  .lower-left .active-lot {
    /* 	  transform: translateY(-50%); */
  }
  .lower-left:after {
    bottom: 45px;
  }
  .lower-left .active-lot {
    /*   transform: translateY(-50%);	 */
  }
  .lower-left .active-lot:before {
    top: 0;
    width: 60px;
    left: 25px;
    right: 0;
    transform: translateY(-210%);
  }
  .lower-left .active-lot h3 {
    text-align: left;
    font-size: 22px;
    transform: initial;
  }
}

@media screen and (min-height: 970px) {
  .main-left {
    padding-top: 275px;
  }
}

@media screen and (min-width: 1366px) and (min-height: 690px) {
  .content-hat {
    flex-direction: row;
  }
  .content-face {
    padding-left: 44%;
  }

  .content-summary {
    padding-left: 0;
  }
  .content-ac {
    width: 72.5%;
  }
  .content-summary .cert-grid.rds,
  .content-summary .cert-grid.grs {
    left: 12px;
  }
}

@media screen and and (min-height: 690px) and (min-width: 1210px) {
  .content-title,
  .content-summary {
    padding-top: 15%;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1400px) and (min-height: 690px) {
  .lower-left .active-lot {
    width: 100%;
  }
  .lower-left .active-lot:before {
    top: 0;
    top: -3px;
    /* 	left:33px;	 */
    left: 50px;
    right: initial;
    transform: initial;
    /* 	transform:translateY(-175%); */
  }
  .lower-left .active-lot h3 {
    /*   text-align:left; */
    text-align: center;
  }
}

@media screen and (min-width: 1440px) {
  .lower-left .active-lot:before {
  }

  .lower-left .active-lot h3 {
    /*   text-align:center; */
  }
}

@media screen and (min-width: 1400px) {
  .content-ac {
    /*     width:72.5%; */
  }
}

.lower-left,
.lower-right {
  overflow: hidden;
  width: auto;
  flex: 1;
  transition: all 1000ms ease;
  /*   transition: flex 0ms ease-in-out, height 1000ms ease-ine-out; */
}

/* .lower-left{background-color:#2ECC40;} */
/* .lower-right{background-color:#3D9970;} */

.lower {
  will-change: flex, opacity;
}
.lower-left:hover {
  flex: 1.25;
  width: 55%;
}

body.cl .lower-left:hover {
  flex: 1;
  width: 100%;
}

.lower-left:hover + .lower-right {
  flex: 0.75;
}

body.sp .lower-right,
body.pr .lower-left {
  /* flex:1.5; */
}
body.sp .lower-left,
body.pr .lower-right {
  /* flex:0.5; */
}

.lower-right:hover {
  flex: 1.25;
}
.lower-right:hover + .lower-right {
  flex: 0.75;
}

footer {
  font-size: 9px;
  font-family: aM, serif;
  text-transform: uppercase;
  padding: 20px 20px;
  padding-top: initial;
  position: relative;
}

footer h3 {
  font-family: hn65, sans-serif;
  font-size: 12px;
}

.content-ac footer {
  padding: initial;
}

@media screen and (min-width: 960px) {
  footer {
    padding: initial;
    position: fixed;
    bottom: 10px;
    left: 25px;
  }
}

@media only screen and (min-width: 768px) {
  .upper-credit,
  .lower-credit {
    font-size: 8px;
  }

  footer {
    font-size: xx-small;
  }
}

/*downcare : shopify*/
.care-link {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body[data-active="CARE"] .spec-title:after {
  color: #fff;
  position: absolute;
  content: "";
  background-image: url("./arrow-right-min.png");
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 19px;
  width: 20px;
  padding-left: 0.5em;
}

body[data-active="SPECIES"] .main-right[data-grs="true"] .spec-title,
body[data-active="SPECIES"] .main-right[data-grs="true"] .spec-value {
  opacity: 0;
}

.shopify-buy__btn {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 0;
  right: 0;
  width: 50%;
  margin: auto;
  padding: 2.5%;

  transform: translateY(-50%);
  -webkit-appearance: none;
  background-color: transparent;

  border: solid 1px #d8d6d9;

  text-align: center;
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-size: 1.2em;
}
.shopify-buy__btn:hover {
  color: #0f0f0f;
  background-color: #f8f8f9;
}

/*
  homePage
*/

body.intro .homePage,
.homePage-bg {
  display: block;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

body.intro .homePage {
  min-height: 100vh;
  height: auto;
}

.homePage-bg video {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  /*
  top: 50%;
  left: 50%;
*/
  /*
  min-width: 100vw;  
  min-height: 100vh;
*/
  /*   transform: translate(-50%, -50%); */
}

.homePage {
  width: 100%;
  align-items: flex-end;
}
.homePage h1 {
  z-index: 10;
  top: 60px;
  left: 0;
  right: 0;
  /* 	bottom:30px; */
  /*   font-size:5em; */
  line-height: initial;
  padding-top: 32%;
}

.homePage > nav a {
  /*   display:inline-block;  */
  margin-left: 30px;
  color: inherit;
}
.homePage h2 {
  margin: initial;
  width: 80px;
  height: 80px;
  color: rgba(0, 0, 0, 0);
  background-image: url("./img/AFD.min.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (min-width: 728px) {
  .homePage h2 {
    width: 110px;
  }
}
body.sticky .homePage h2 {
  background-image: url("./img/AFD-k.min.svg");
}

.homePage > nav,
#root > header,
.interiorNav {
  /*   background-color:#f8f8f9; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

.homePage > nav {
  top: 38px;
}

@media only screen and (min-width: 768px) {
  body[data-active="SPECIES"] .interiorNav,
  body[data-active="CONTENT"] .interiorNav,
  body[data-active="CLEANLINESS"] .interiorNav,
  body[data-active="CARE"] .interiorNav {
    mix-blend-mode: exclusion;
  }
}

#root > header,
#root > header a,
.interiorNav {
  position: relative;
}

.interiorNav {
  padding: 30px 0 0 0;
  mix-blend-mode: exclusion;
}

.interiorNav a,
.interiorNav .language select,
.interiorNav .language:after {
  color: #fff;
}

.homePage .formWrap {
  position: absolute;
  z-index: 200;
  /*   left:30px; */
  left: 0;
  right: 0;
  bottom: 10vh;
  width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  max-width: 422px;
  height: auto;
  height: 220px;
}

@media only screen and (min-height: 569px) {
  .homePage .formWrap {
    height: 300px;
  }
}

.homePage .formWrap h1 {
  width: 100%;
  margin: auto;
}

@media only screen and (min-width: 677px) {
  .homePage .formWrap {
    max-height: 40vh;
    top: 0;
    left: 0;
    margin: auto;
    margin-left: 30px;
    bottom: 0;
  }
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  #root > header,
  #root > header a {
    position: absolute;
  }
}

.homePage > nav ul,
.interiorNav ul {
  list-style-type: none;
  /*
  width:100%;
  max-width:768px;	
*/
  width: 95%;
  /*   margin-right:0; */
  display: flex;
  flex-direction: row;
}

.homePage > nav ul,
.interiorNav ul {
  box-sizing: border-box;
  flex-direction: column;
  width: 90%;
  margin: auto;
  /*   padding-left:30px; */
}

@media only screen and (min-width: 321px) {
  .homePage > nav ul,
  .interiorNav ul {
    flex-direction: row;
    flex-wrap: wrap;
    /*
    height:100px;
    height:70px;
*/
    margin: initial;
    margin-left: 30px;
  }
}
@media only screen and (min-width: 960px) and (min-height: 690px) {
  .homePage > nav ul,
  .interiorNav ul {
    flex-direction: row;
    height: initial;
    padding-left: initial;
  }
}

.homePage > nav,
.homePage > nav ul {
  flex-direction: column;
  height:100px;
}

.homePage > nav ul li,
.interiorNav ul li {
  position: relative;
  width: 100%;
  width: unset;
  font-size: 12px;
  line-height: 2.2em;
  color: #f8f8f9;
  display: block;
  display: inline-block;
  /*   margin: 0 2.5%; */
}

@media only screen and (min-width: 321px) {
  .homePage > nav ul li,
  .interiorNav ul li {
    /*     width:50%; */
    /*     max-width:175px; */
    margin-right: 1em;
  }
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  .homePage > nav ul li,
  .interiorNav ul li {
    display: inline-block;
    width: 100%;
    max-width: unset;
    flex: none;
  }
}
.homePage > nav ul li a,
.interiorNav a {
  position: relative;
}

.homePage > nav ul li a:before,
.interiorNav a:before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #fff;
  transition: width 500ms ease-in-out;
}

.interiorNav a:before {
  bottom: -5px;
}

.homePage > nav ul li:last-child a:before,
.interiorNav li:last-child a:before {
  display: none;
  transition: none;
}

.homePage > nav ul li a:hover:before,
.interiorNav ul li a:hover:before {
  width: 100%;
}

@media only screen and (min-width: 620px) {
  .homePage > nav,
  .homePage > nav ul {
    flex-direction: row;    
  }

  .homePage > nav ul {
    width: 100%;
    /*     max-width:860px; */
    justify-content: flex-end;
    margin-left: 120px;
    height:initial;
  }

  .homePage > nav ul li {
    width: initial;
    margin: 0 10px;
    padding-left: initial;
    text-align: center;
  }
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  .interiorNav {
    position: absolute;
    top: 38px;
    padding: initial;
    justify-content: flex-end;
  }

  .interiorNav ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* max-width: 860px; */
    justify-content: flex-end;
    /* margin-left: 120px; */
    margin-right: 30px;
  }

  .interiorNav ul li {
    width: initial;
    margin: 0 10px;
    padding-left: initial;
    text-align: center;
  }
}

@media only screen and (min-width: 1400px) {
  .interiorNav ul {
    /*    padding-right:120px;	 */
    padding-right: 30px;
  }
}

body.sticky .homePage > nav ul li,
.interiorNav ul li {
  color: #000000;
}

.homePage > nav ul li:last-of-type,
.interiorNav ul li:last-of-type {
  height: 1.632em;
  /*   margin-top:-1px;   */
}

@media only screen and (min-width: 960px) {
  .homePage > nav ul li:last-of-type {
    margin-left: 0;
    /*   margin-right:0; */
  }

  .interiorNav ul li:last-of-type {
    margin-left: 0;
    margin-right: 0;
  }
}

.homePage > nav ul li:hover,
.interiorNav ul li:hover {
  cursor: pointer;
}

.homePage > nav ul li a {
  color: inherit;
  margin: initial;
  line-height: 2em;
}

.homePage > nav ul li > div {
  /*
  position:absolute;
  top:3px;
  left:0;
  width:60px;
  display:block;
*/
}
.homePage > nav ul li label {
  display: inline-block;
}
.homePage > nav ul li input {
  display: inline-block;
  width: 1em;
  line-height: 12px;
}
.homePage > nav ul li div:nth-child(2) {
  top: 2em;
}

.homePage-intro {
  position: relative;
  z-index: 20;
  display: none;
  height: 150vh;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .homePage-intro {
    height: 100vh;
  }
}

/*navigation*/
.language .hidden,
.language input {
  display: none;
}

.language {
  position: relative;
}

.language:after {
  position: absolute;
  /*   top: 3px; */
  top: 0;
  left: 4em;
  /*   transform:translateY(100%); */
  content: " ▼";
  font-size: 7px;
}

.language select {
  /* A reset of styles, including removing the default dropdown arrow*/
  appearance: none;
  /* Additional resets for further consistency */
  background-color: transparent;
  border: none;
  /*   padding: 1em; */
  /*   padding-left:0; */
  /*   transform: translatey(-20%); */
  /*   margin: 0; */
  /*   width: 2.5em; */
  width: 4.5em;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  color: inherit;
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  .language select {
    padding-top: 0;
    padding-left: 1em;
  }

  .language:after {
    left: initial;
    right: 12px;
    transform: translateY(-1px);
  }
}

body.intro .homePage-intro {
  display: flex;
}

body.intro .footer {
  display: block;
}

body[data-active="lot"] .homepage-intro,
body:before {
  content: "";
  position: fixed;
  z-index: 9100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 1;
  visibility: visible;
  transition: opacity 750ms, visibility 0s 750ms;
}

body.intro:before,
body.loaded:before {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1000ms ease;
}
body.loaded .search-form {
  display: none;
}

.homePage-help {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  transform: translateY(200%);
}

@media only screen and (min-width: 620px) {
  .homePage-help {
    transform: translateY(150%);
  }
}

@media only screen and (min-width: 960px) and (min-height: 690px) {
  .homePage-help {
    transform: translateY(50%);
  }
}

.homePage-help p {
  margin: initial;
  line-height: 1.2em;
}
.homePage-help span {
  font-family: monospace;
}
.homePage-help a {
  color: inherit;
  text-decoration: none;
  padding-bottom: 3px;
  margin: initial;
  border-bottom: solid 1px #f8f8f9;
}
.homePage-help pre {
  margin: initial;
}
.homePage-intro .wrap {
  box-sizing: border-box;
  width: 100%;
  max-width: 1024px;
  max-width: 960px;
  margin: auto;
  padding: 2.5%;
  color: #f8f8f9;
}

.homePage-intro .wrap h3 {
  margin: initial;
  font-family: hn65, sans-serif;
  font-size: 40px;
  line-height: 1.28em;
}
.homePage-intro .wrap h3:after {
  position: relative;
  display: block;
  content: "";
  margin-top: 10px;
  height: 2px;
  width: 100%;
  background-color: #f8f8f9;
}
.homePage-intro .wrap p {
  font-family: hn65, sans-serif;
  font-size: 28px;
  font-size: 19px;
  line-height: 1.28em;
  line-height: 1.8em;
}

.homePage .homePage-bg > button {
  position: absolute;
  z-index: 10;
  color: #f8f8f9;
  height: 45px;
  width: 45px;
  bottom: 0;
  left: 15px;
  padding: initial;
}

.homePage .homePage-bg > button svg {
  position: absolute;
  top: 0;
  left: 0;
}

.homePage .homePage-bg > button.pause .pause {
  display: none;
}

.homePage .homePage-bg > button.pause .play {
  display: block;
}

.homePage .homePage-bg > button .play {
  display: none;
}

/*footer*/
.site-foot {
  /* position:relative; */
  width: 100%;
  bottom: initial;
  left: initial;
  padding: 50px 0;
  min-height: 250px;
  background-color: rgba(170, 170, 170, 0.1);
}

body.intro .site-foot {
  position: relative;
}

.site-foot a {
  color: inherit;
  cursor: pointer;
}

.site-foot .badge {
  display: block;
  position: relative;
  margin-left: 5%;
  margin-bottom: 30px;
  /* 		    background-color:#111; */
  background-image: url(//v2.alliedfeather.com/wp-content/themes/afd-com/img/AFD-2020.min.svg);
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;
  width: 45px;
  height: 57px;
}
@media only screen and (min-width: 1024px) {
  .site-foot .badge {
    width: 97px;
    background-position: left;
    background-image: url(//v2.alliedfeather.com/wp-content/themes/afd-com/img/AFD-2020-wordmark.min.svg);
  }
}
.site-foot nav h3 {
  color: #f8f8f9;
  font-weight: normal;
  margin-top: initial;
}
.site-foot > ul,
.site-foot nav {
  font-size: 9px;
  padding: initial;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: initial;
  /*padding:0 10%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.site-foot nav section.wider {
  width: 60%;
}

.site-foot nav section:nth-child(2) {
  width: 40%;
}

@media only screen and (min-width: 1024px) {
  .site-foot > ul,
  .site-foot nav {
    font-size: 0.75em;
    font-size: 12px;
  }
  footer h3 {
    font-size: 14px;
  }
}

.site-foot section {
  width: 50%;
  margin: auto;
  margin-top: initial;
  margin-left: initial;
  display: inline-block;
}
.site-foot ul > li {
  display: inline-block;
}

@media screen and (min-width: 960px) {
  .site-foot section {
    max-width: 175px;
    margin: auto;
    margin-top: initial;
    margin-left: initial;
    margin-right: 5%;
    display: inline-block;
  }
}

.site-foot section {
  margin-bottom: 5%;
}

.site-foot ul > li:first-child {
  margin-left: initial;
}
.site-foot ul > li:last-child {
  margin-right: initial;
}
.site-foot li ul,
.site-foot section ul {
  padding: initial;
}
.site-foot li ul li,
.site-foot section ul li {
  display: block;
  /* 		    margin-bottom:1em; */
}
.site-foot .subnav {
  padding-bottom: 1.49em;
}

@media only screen and (min-width: 768px) {
  .site-foot .badge {
    position: absolute;
    /* 		      left:10%;		       */
    top: 50px;
    margin-bottom: initial;
  }

  .site-foot .badge.mark {
    top: 50px;
  }

  .site-foot > ul,
  .site-foot nav {
    box-sizing: border-box;
    padding: 0 2.5%;
    max-width: 960px;
    margin: auto;

    /*
	  margin-left:16%;
	  margin-right:16%;
*/
  }
  .site-foot section {
    margin-right: auto;
    margin-bottom: auto;
    min-width: 15%;
  }
  .site-foot section.wider {
    min-width: 33%;
  }
  .site-foot li ul li,
  .site-foot section ul li {
    margin-bottom: 1em;
  }
}

/*bespoke*/
body .main-right[data-brand="0110"] .il.upper-bg {
  background-image: url("img/0110/aside-ATA-Header-Clean.png");
}
body .main-right[data-brand="0110"][data-grs="true"] .il.upper-bg {
  background-image: url("img/aside-one-grs.jpg");
}

body .main-right[data-brand="0110"] .lower-left .lower-bg {
  background-image: url("img/0110/aside-coldseason-layer.jpg");
}
body .main-right[data-brand="0110"][data-grs="true"] .lower-left .lower-bg {
  background-image: url("img/0110/cleanliness-austria-preview.jpg");
}

body .main-right[data-brand="0110"] .lower-right .lower-bg {
  background-image: url("img/0110/aside-two-0110.jpg");
  opacity: 0.45;
}
body
  .main-right[data-brand="0110"][data-active="CLEANLINESS"]
  .upper-bg
  + .il-add {
  background-image: url("img/0110/cleanliness-austria-preview.jpg") !important;
}
body
  .main-right[data-brand="0110"][data-active="CLEANLINESS"][data-grs="true"]
  .upper-bg
  + .il-add {
  background-image: url("img/0110/aside-monochrome-hood.jpg") !important;
}

body .main-right[data-brand="0110"][data-active="TREATMENT"] .il-add.tr {
  background-image: url("img/0110/treatment-black-down-blend.jpg") !important;
}

body .main-right[data-brand="0110"][data-active="CM"] .upper-bg + .il-add {
  background-image: url("img/0110/aside-FUTURISTIC-ACOUSTICS-a.jpg") !important;
}

body .main-right[data-brand="0116"] .il.upper-bg {
  background-image: url("img/0116/afd-DISPLACE-2.jpg");
  opacity: 0.85;
}

body .main-right[data-brand="0116"] .lower-right .lower-bg {
  background-image: url("img/0116/aside-twoB.jpg");
  opacity: 0.45;
}

body .main-right[data-brand="0116"] .lower-left .lower-bg {
  background-image: url("img/0116/aside-hood.jpg");
  opacity: 0.45;
}

body
  .main-right[data-brand="0116"][data-active="CLEANLINESS"]
  .upper-bg
  + .il-add {
  background-image: url("img/0116/care-tips.jpg") !important;
}

body .main-right[data-brand="2206"] .il.upper-bg {
  background-image: url("img/2206/itc-scorched.jpg");
  background-position: top center;
}

body .main-right[data-brand="2206"] .lower-left .lower-bg {
  background-image: url("img/2206/men-s23_veilance.jpg");
}

body .main-right[data-brand="2206"] .lower-right .lower-bg {
  /* 	background-image: url(/img/2206/aside-two.jpg); */
  background-image: url("img/2206/aside-veilance.jpg");
}

body .main-right[data-brand="2206"][data-active="TREATMENT"] .il-add.tr {
  background-image: url("img/2206/afd-DISPLACE2.jpg") !important;
}

body
  .main-right[data-brand="2206"][data-active="CLEANLINESS"]
  .upper-bg
  + .il-add {
  background-image: url("img/0116/care-tips.jpg") !important;
}

body .main-right[data-brand="1001"] .il.upper-bg {
  background-image: url("img/1001/1001-upper.jpg");
}

body .main-right[data-brand="1001"] .lower-left .lower-bg {
  background-image: url("img/1001/1001-lowerLeft.jpg");
}

body .main-right[data-brand="1001"] .lower-right .lower-bg {
  background-image: url("img/1001/1001-lowerRight.jpg");
}

body .main-right[data-brand="1029"] .il.upper-bg {
  background-image: url("img/1029/1001-upper.jpg");
}

body .main-right[data-brand="1029"] .lower-left .lower-bg {
  background-image: url("img/1029/1001-lowerLeft.jpg");
}

body .main-right[data-brand="1029"] .lower-right .lower-bg {
  background-image: url("img/1029/1001-lowerRight.jpg");
}

body .main-right[data-brand="1027"] .il.upper-bg {
  background-image: url("img/1027/1027-upper.jpg");
}

body .main-right[data-brand="1027"] .lower-left .lower-bg {
  background-image: url("img/1027/1027-lowerLeft.jpg");
}

body .main-right[data-brand="1027"] .lower-right .lower-bg {
  background-image: url("img/1027/1027-lowerRight.jpg");
}

body [data-email="10270122353"] .main-right[data-brand="1027"] .il.upper-bg {
  background-image: url("img/1027/1027-upper-expedry.jpg");
}

body
  [data-email="10270122353"]
  .main-right[data-brand="1027"]
  .lower-left
  .lower-bg {
  background-image: url("img/1027/1027-lowerLeft-expedry.jpg");
}

body
  [data-email="10270122353"]
  .main-right[data-brand="1027"]
  .lower-right
  .lower-bg {
  background-image: url("img/1027/1027-lowerRight-expedry.jpg");
}

body .main-right[data-brand="1028"] .il.upper-bg {
  background-image: url("img/1028/1028-upper-ski.jpg");
}

body .main-right[data-brand="1028"] .lower-left .lower-bg {
  background-image: url("img/1028/1028-lowerLeft-ski.jpg");
}

body .main-right[data-brand="1028"] .lower-right .lower-bg {
  background-image: url("img/1028/1028-lowerRight-ski.jpg");
}

body .main-right[data-brand="1030"] .il.upper-bg {
  background-image: url("img/1028/1028-upper-ski.jpg");
}

body .main-right[data-brand="1030"] .lower-left .lower-bg {
  background-image: url("img/1028/1028-lowerLeft-ski.jpg");
}

body .main-right[data-brand="1030"] .lower-right .lower-bg {
  background-image: url("img/1028/1028-lowerRight-ski.jpg");
}
body .main-right[data-brand="1030"] .lower-left .lower-bg {
  background-image: url("img/1030/1030-lowerLeft-down.jpg");
}

body .main-right[data-brand="1030"] .lower-right .lower-bg {
  background-image: url("img/1028/1028-lowerRight-ski.jpg");
}

body .main-right[data-brand="1030"][data-treatment="ExpeDRY"] .il.upper-bg {
  background-image: url("img/1030/1030-upper-discovery.jpg");
}

body .main-right[data-brand="1030"][data-treatment="ExpeDRY"] .lower-left .lower-bg {
  background-image: url("img/1030/1030-lowerLeft-discovery.jpg");
}

body .main-right[data-brand="1030"][data-treatment="ExpeDRY"] .lower-right .lower-bg {
  background-image: url("img/1030/1030-lowerRight-discovery.jpg");
}

body .main-right[data-brand="1107"] .il.upper-bg {
  background-image: url("img/1107/aside-one.jpg");
}

body .main-right[data-brand="1107"] .lower-left .lower-bg {
  background-image: url("img/1107/aside-two.jpg");
}

body .main-right[data-brand="1107"] .lower-right .lower-bg {
  background-image: url("img/1107/aside-three.jpg");
}

body [data-email="0324L23FN2790"] .main-right[data-brand="0324"] .il.upper-bg {
  background-image: url("img/0324/0324-upper.jpg");
}

body
  [data-email="0324L23FN2790"]
  .main-right[data-brand="0324"]
  .lower-left
  .lower-bg {
  background-image: url("img/0324/0324-lowerLeft.jpg");
}

body
  [data-email="0324L23FN2790"]
  .main-right[data-brand="0324"]
  .lower-right
  .lower-bg {
  background-image: url("img/0324/0324-lowerRight.jpg");
}

body [data-email="03240315388"] .main-right[data-brand="0324"] .il.upper-bg {
  background-image: url("img/0324/0324-upper-expedry.jpg");
}

body
  [data-email="03240315388"]
  .main-right[data-brand="0324"]
  .lower-left
  .lower-bg {
  background-image: url("img/0324/0324-lowerLeft-expedry.jpg");
}

body
  [data-email="03240315388"]
  .main-right[data-brand="0324"]
  .lower-right
  .lower-bg {
  background-image: url("img/0324/0324-lowerRight-expedry.jpg");
}

body .main-right[data-brand="0805"] .il.upper-bg {
  background-image: url("img/0805/0805-upper.jpg");
}

body .main-right[data-brand="0805"] .lower-left .lower-bg {
  background-image: url("img/0805/0805-lowerLeft.jpg");
}

body .main-right[data-brand="0805"] .lower-right .lower-bg {
  background-image: url("img/0805/0805-lowerRight.jpg");
}

body .main-right[data-brand="0236"] .il.upper-bg {
  background-image: url("img/0236/0236-upper.jpg");
}

body .main-right[data-brand="0236"] .lower-left .lower-bg {
  background-image: url("img/0236/0236-lowerLeft.jpg");
}

body .main-right[data-brand="0236"] .lower-right .lower-bg {
  background-image: url("img/0236/0236-lowerRight.jpg");
}

body .main-right[data-brand="1524"] .il.upper-bg {
  background-image: url("img/1524/1524-upper.jpg");
}

body .main-right[data-brand="1524"] .lower-left .lower-bg {
  background-image: url("img/1524/1524-lowerLeft.jpg");
}

body .main-right[data-brand="1524"] .lower-right .lower-bg {
  background-image: url("img/1524/1524-lowerRight.jpg");
}
